<template>
  <vx-card title="Manage Collection Data">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Collection Plan ID</label>
        <vs-input class="w-full" v-model="uuid" type="text" readonly />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Territory</label>
        <vs-input class="w-full" v-model="territory" type="text" readonly />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Collector / Sales</label>
        <vs-input
          class="w-full"
          v-model="collector_sales"
          type="text"
          readonly
        />
      </div>
    </div>

    <!-- table collection plan -->
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <vs-table
          search
          stripe
          border
          description
          :sst="false"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
        >
          <template slot="thead">
            <!-- <vs-th>Payment Info</vs-th> -->
            <vs-th>Customer ID / Name</vs-th>
            <vs-th>Invoice Info</vs-th>
            <vs-th>Due Date</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td> {{ tr.customer_code }} - {{ tr.customer_name }}</vs-td>
              <vs-td>
                Number: {{ tr.invoice_no }} <br />
                Date: {{ tr.invoice_date | formatDate }} <br />
                Outstanding: {{ tr.outstanding_value | formatNumber }}
              </vs-td>
              <vs-td>{{ tr.due_date | formatDate }}</vs-td>

              <!-- expand payment info -->
              <template slot="expand">
                <div class="vx-row" :style="{ overflow: 'auto' }">
                  <div class="vx-col mb-2">
                    <h4>Payment Info</h4>
                  </div>
                  <div class="vx-col">
                    <vs-table
                      :style="{ width: '200%' }"
                      stripe
                      border
                      :sst="false"
                      :data="tr.customer_order_payments"
                      :total="tr.customer_order_payments.length"
                      :searchable="false"
                    >
                      <template slot="thead">
                        <vs-th>Payment Method</vs-th>
                        <vs-th>Notes</vs-th>
                        <vs-th>Payment Value</vs-th>
                        <vs-th>No Payment Reason</vs-th>
                        <vs-th>Promise Date</vs-th>
                        <vs-th>Number (Giro)</vs-th>
                        <vs-th>Transfer Date (Giro)</vs-th>
                        <vs-th>Transfer Date (Bank Transfer)</vs-th>
                        <vs-th>Bank Name</vs-th>
                        <vs-th>Deduction Value</vs-th>
                        <vs-th>Deduction Reason</vs-th>
                        <vs-th>Photo Proof of Payment</vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td>
                            current:
                            <b>{{ tr.payment_method_name | checkName }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <vs-select
                                class="selectExample"
                                v-model="tr.new_payment_method_id"
                                disabled
                              >
                                <vs-select-item
                                  :key="index"
                                  :value="item.id"
                                  :text="item.name"
                                  v-for="(item, index) in optionPaymentMethod"
                                />
                              </vs-select>
                            </div>
                          </vs-td>
                          <vs-td>
                            <vs-textarea
                              class="w-full"
                              type="number"
                              style="margin-bottom: 60px"
                              @input="doEnableButtonSave"
                              v-model="tr.notes"
                            />
                          </vs-td>
                          <vs-td>
                            current:
                            <b>{{ tr.payment_value | checkIfEmpty }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <vs-input
                                class="w-full"
                                type="number"
                                v-model="tr.new_payment_value"
                                readonly
                              />
                            </div>
                          </vs-td>
                          <vs-td>
                            <b>{{ tr.no_payment_reason | checkIfEmpty }}</b>
                          </vs-td>
                          <vs-td>
                            <b>{{ tr.promise_date | formatDate }}</b>
                          </vs-td>
                          <vs-td>
                            current:
                            <b>{{ tr.giro_number | checkIfEmpty }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <vs-input
                                class="w-full"
                                type="text"
                                v-model="tr.new_giro_number"
                                readonly
                              />
                            </div>
                          </vs-td>
                          <vs-td>
                            current:
                            <b>{{ tr.giro_transfer_date | formatDate }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <vs-input
                                class="w-full"
                                type="date"
                                v-model="tr.new_giro_transfer_date"
                                readonly
                              />
                            </div>
                          </vs-td>
                          <vs-td>
                            current:
                            <b>{{ tr.date_transfer | formatDate }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <vs-input
                                class="w-full"
                                type="date"
                                v-model="tr.new_date_transfer"
                                readonly
                              />
                            </div>
                          </vs-td>
                          <vs-td>
                            current:
                            <b>{{ tr.bank_name | checkIfEmpty }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <b>{{ tr.new_bank_name | checkIfEmpty }}</b>
                            </div>
                          </vs-td>
                          <vs-td>
                            current:
                            <b>{{ tr.deduction_value | checkIfEmpty }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <vs-input
                                class="w-full"
                                type="number"
                                v-model="tr.new_deduction_value"
                                readonly
                              />
                            </div>
                          </vs-td>
                          <vs-td>
                            current:
                            <b>{{ tr.deduction_reason | checkIfEmpty }}</b>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <vs-input
                                class="w-full"
                                type="text"
                                v-model="tr.new_deduction_reason"
                                readonly
                              />
                            </div>
                          </vs-td>
                          <vs-td>
                            current:
                            <a
                              v-show="tr.photo_proof_of_payment != ''"
                              :href="tr.photo_proof_of_payment"
                              target="_blank"
                            >
                              <vs-button
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-eye"
                              />
                            </a>
                            <br />
                            <div v-show="tr.is_history">
                              change:
                              <a
                                v-show="tr.new_photo_proof_of_payment != ''"
                                :href="tr.new_photo_proof_of_payment"
                                target="_blank"
                              >
                                <vs-button
                                  size="small"
                                  color="success"
                                  icon-pack="feather"
                                  icon="icon-eye"
                                />
                              </a>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="vx-row my-4">
      <div class="vx-col flex items-center gap-2">
        <vs-button
          @click="() => $router.go(-1)"
          size="small"
          color="danger"
          icon-pack="feather"
          icon="icon-arrow-left"
          >Back</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      // main data
      uuid: null,
      territory: null,
      collector_sales: null,
      data: null,
      table: this.defaultTable(),
      //   modal edit
      modalEdit: {
        index: null,
        activeModalEdit: false,
        giro_check: null,
        transfer_date: null,
      },

      // option bank
      selectedBank: null,
      optionBank: [],
      optionPaymentMethod: [],
      selectedPaymentMethod: null,
    };
  },
  methods: {
    fetchPaymentMethod(params) {
      this.$vs.loading();
      this.$http
        .get(`api/sfa/v1/mobile-preparation/payment-method`, { params: params })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            // renamce COD to Cash
            this.optionPaymentMethod = resp.data.map((item) => {
              if (item.code == "COD") {
                item.name = "Cash";
              }
              return item;
            });
          }
        });
    },
    async putApprovalCollection(id, payload) {
      let response = await this.$http.put(
        `/api/sfa/v1/collection-plan/${id}/approval`,
        payload
      );

      return response;
    },
    async onClickBtnApprove() {
      this.$vs.loading();
      const id = this.$route.params.id;
      let payload = {
        id: parseInt(id),
        approved_status: 2,
      };

      let response = await this.putApprovalCollection(id, payload);
      if (response.code === 200) {
        this.$vs.notify({
          title: "Success",
          text: "Approved",
          color: "success",
        });
        this.$router.push("/approval/manage-collection-change-approval");
      } else {
        this.$vs.notify({
          title: "Failed",
          text: "Approved",
          color: "danger",
        });
      }

      this.$vs.loading.close();
    },
    async onClickBtnReject() {
      this.$vs.loading();
      const id = this.$route.params.id;
      let payload = {
        id: parseInt(id),
        approved_status: parseInt(99),
      };

      let response = await this.putApprovalCollection(id, payload);
      if (response.code === 200) {
        this.$vs.notify({
          title: "Success",
          text: response.message,
          color: "success",
        });
        this.$router.push("/approval/manage-collection-change-approval");
      } else {
        this.$vs.notify({
          title: "Failed",
          text: response.message,
          color: "danger",
        });
      }

      this.$vs.loading.close();
    },
    onCancelModalEdit() {
      this.modalEdit.activeModalEdit = false;
    },
    onAcceptModalEdit() {
      this.modalEdit.activeModalEdit = false;
      // assign value
      this.data.routes[this.modalEdit.index].giro_number =
        this.modalEdit.giro_check;
      this.data.routes[this.modalEdit.index].giro_transfer_bank_id =
        this.selectedBank.id;
      this.data.routes[this.modalEdit.index].giro_transfer_date =
        this.modalEdit.transfer_date;
    },
    onCloseModalEdit() {
      this.modalEdit.activeModalEdit = false;
    },

    // main method
    async onClickBtnSave() {
      let payload = {
        collection_plan_lines: this.data.routes.map((item) => {
          return {
            id: item.collection_plan_line_id,
            deduction_value: parseFloat(item.deduction_value),
            deduction_reason: item.deduction_reason,
            giro_number: item.giro_number,
            giro_transfer_bank_id: item.giro_transfer_bank_id,
            giro_transfer_date: item.giro_transfer_date,
            return_document: item.return_document,
          };
        }),
      };
      this.$vs.loading();
      const id = this.$route.params.id;
      let response = await this.$http.put(
        `/api/sfa/v1/collection-plan/manage/${id}`,
        payload
      );

      if (response.code == 200) {
        this.$vs.notify({
          title: "Success",
          text: "Data has been saved",
          color: "success",
        });
        this.$vs.loading.close();
        this.$router.push("/collection-plan-manage");
      } else {
        this.$vs.notify({
          title: "Failed",
          text: "Data failed to save",
          color: "danger",
        });
        this.$vs.loading.close();
      }
    },
    async onClickBtnEdit(tr, index) {
      this.modalEdit.index = index;
      this.modalEdit.activeModalEdit = true;
      this.modalEdit.giro_check = tr.giro_number;
      this.modalEdit.transfer_date = moment(tr.giro_transfer_date).format(
        "YYYY-MM-DD"
      );
      if (tr.giro_transfer_bank_id != 0) {
        const bank = await this.$http.get(
          `/api/sfa/v1/bank/${tr.giro_transfer_bank_id}`
        );

        this.selectedBank = bank.data;
      }
    },
    async fetchCollectionPlan() {},
    async customLabelCollectionPlan(option) {
      return `${option.uuid} - ${option.name}`;
    },
    defaultTable() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      const id = this.$route.params.id;
      this.$vs.loading();
      let response = await this.$http.get(`/api/sfa/v1/collection-plan/${id}`, {
        params: {
          is_show_history: true,
          is_review_mode: true,
        },
      });

      let data = response.data;

      this.data = data;
      this.uuid = data.uuid;
      this.territory = `${data.territory_code} - ${data.territory_name}`;
      this.collector_sales = `${data.sales_number} - ${data.sales_name}`;
      this.table.data = data.routes;

      this.$vs.loading.close();
    },

    // bank
    async getOptionBank(params) {
      this.$vs.loading();
      let response = await this.$http.get(`/api/sfa/v1/bank`, params);

      let data = response.data;

      this.optionBank = data.records;

      this.$vs.loading.close();
    },
    customLabelBank(option) {
      return `${option.code} - ${option.name}`;
    },
    onSearchBank(search) {
      if (search.length > 2) {
        let params = {
          params: {
            search: search,
          },
        };
        this.getOptionBank(params);
      }
    },
  },
  async mounted() {
    await this.getData();
    await this.fetchPaymentMethod({
      is_mobile: true,
    });
  },
  filters: {
    checkName(val) {
      if (val == "COD (Cash On Delivery)") {
        return "Cash";
      }
      return val;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    checkIfEmpty(value) {
      if (value == "") {
        return "-";
      } else if (value == null) {
        return "-";
      } else if (value == 0) {
        return "-";
      } else {
        if (value == "COD (Cash On Delivery)") {
          return "Cash";
        }
        return value;
      }
    },
  },
};
</script>
